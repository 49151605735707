.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
}

.modal {
  background-color: #fefefe;
  position: fixed;
  top: 15vh;
  left: 10%;
  width: 80%;
  z-index: 100;
  overflow: hidden;
}

.header {
  background: #ff64cb;
  padding: 1rem;
}

.header h2 {
  margin: 0;
  color: white;
}

.content {
  padding: 1rem;
  height: 20rem;
  overflow: hidden;
  overflow-y: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.content p {
  margin-block-end: 0;
  text-align: left;
  font-size: 20px;
}

.content::-webkit-scrollbar {
  width: 1vw;
}

.content::-webkit-scrollbar-thumb {
  background-color: #5f5e5e42;
}

.content::-webkit-scrollbar-track {
  background-color: #fff6f6;
}

.content::-webkit-scrollbar-thumb:hover {
  background-color: #5f5e5e;
}

.content::-webkit-scrollbar-track:hover {
  background-color: #fff6f6;
}

.actions {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}
.actions button {
  padding: 0.5rem 2rem;
  margin-right: 0;
  margin-left: auto;
  font: inherit;
  cursor: pointer;
  font-size: 20px;
  color: white;
  border-radius: 12px;
  border: 1px solid #40005d;
  background-color: #40005d;
}
.actions button:hover,
.actions button:active {
  background-color: #ff64cb;
  border-color: #fa5cc5;
}

@media (min-width: 768px) {
  .modal {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}
