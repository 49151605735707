.contactForm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  width: 100%;
}
.contactForm input {
  border-radius: 5px;
  margin-bottom: 1em;
  margin-top: 0.5em;
  padding: 0.5em;
}

.contactForm textarea {
  border-radius: 5px;
  margin-bottom: 1em;
  width: 100%;
  height: 6em;
}

.contactForm button {
  padding: 0.5em 1em;
  width: fit-content;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
}
.cancel {
  background-color: darkgrey;
  border: darkgrey;
  color: #fff;
}
.cancel:hover {
  background-color: #fc83d3;
  border: #fc83d3;
  transition: 0.5s ease-in-out;
}
.submit {
  background-color: #ff64cb;
  color: #fff;
  border: #ff64cb;
}

.submit:hover {
  background-color: #fc83d3;
  border: #fc83d3;
  transition: 0.5s ease-in-out;
}
.actions {
  display: flex;
  justify-content: space-between;
}
