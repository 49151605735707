@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Poppins:wght@300;400;700&display=swap");
html {
  scroll-behavior: smooth;
}
body {
  height: 100vh;
  margin: 0;
  font-family: "Poppins", arial;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}
img {
  width: 100%;
}
