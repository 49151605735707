.skills {
  background: #ff64cb;
  padding: 3em 1em;
}

.skills h2 {
  color: white;
  font-size: 3em;
  top: 0;
  margin-top: 0;
  text-align: center;
}

.skills-container ul li {
  background: #f7f7f7;
  padding: 2em;
  border-radius: 1em;
  margin-bottom: 1em;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.icon-container {
  height: 100px;
  display: grid;
  place-content: center;
  margin: 0 auto;
}

.icon-container img {
  height: 100px;
  width: 100px;
}
.skill-title {
  font-weight: bold;
  font-size: 1.3em;
  text-align: center;
}
.skill-desc {
  margin-bottom: 2em;
  color: #3b3b3b;
  font-size: 1.2em;
  line-height: 1.8em;
  font-weight: 500;
  text-align: center;
}

@media only screen and (min-width: 800px) {
  .skills {
    background: #ff64cb;
    margin-top: -5em;
    margin-bottom: 1em;
    padding-bottom: -10px;
  }

  .python {
    white-space: pre-line;
  }
  .skills-container {
    padding: 0 2.5em;
  }
  .skills-container ul {
    display: grid;
    grid-template-columns: repeat(3, 30%);
    align-items: center;
    justify-content: center;
    grid-gap: 1em;
  }
  .skills-container li {
    height: 20em;
  }
  .icon-container img {
    width: 5em;
  }
  .skill-title {
    font-size: 0.9em;
  }
  .skill-desc {
    font-size: 0.9em;
  }
}
@media only screen and (min-width: 1050px) {
  .skills-container li {
    height: 23em;
  }
  .icon-container img {
    width: 5em;
  }
  .skill-title {
    font-size: 1.2em;
  }
  .skill-desc {
    font-size: 1.1em;
  }
}
